.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  display: block;
  margin-top: 4vh;
  font-size: calc(8px + 2vmin);
}

.red {
  color: #ed2e4a
}

.info {
  height: 10vh;
}

.divider {
  margin: 0 4vw;
}

h1, h2 {
  font-weight: normal;
  margin: 0 auto;
  line-height: 1.4;
}

h1 {
  font-weight: 500;
}
h2 {
  font-weight: 400;
}